import React from "react";
import "./sparrow.css";
import logo from "../assets/Logo.png";
import playerIcon from "../assets/icon7.webp";
import icon1 from "../assets/icon2.webp";
import icon2 from "../assets/icon3.png";
import icon3 from "../assets/icon4.png";
import icon4 from "../assets/icon5.png";
import icon5 from "../assets/icon6.png";
import icon6 from "../assets/icon9.png";
import basketBall from "../assets/basket.webp";
import tesniss from "../assets/teniss.webp";
import football from "../assets/football.webp";
import kabbadi from "../assets/kabbadi.webp";
import Footer from "./footer/Footer";

const SparrowGame = () => {
  return (
    <section className="backgroundImg ">
      <section className="parentClass px-7 ">
        <div className="header flex justify-between lg:mx-2 lg:px-8">
          <div className="">
            <img className="lg:w-42 lg:h-32 ml-1 lg:ml-2  w-30 h-20" src={logo} alt="" />
          </div>

          <div className="flex justify-center items-center ">
            <button className="rounded-xl text-xl font-bold border-2 lg:py-2 py-1 px-3 lg:px-10 text-brandClr">
              <a href="https://wa.link/baazigar?text=I%20want%20new%20id">
                Sign Up
              </a>
            </button>
          </div>
        </div>
        <div className="relative main font-brand-font  font-normal pt-4 lg;pt-3 gap-20  lg:mx-10 lg:px-8  lg:pl-10 lg:gap-25">
          <div className=" lg:my-4">
            <p className="text-white  lg:text-2xl fotn-bold tracking-tighter">
              Passionate about Sports?
            </p>
          </div>
          <div className="">
            <h1 className="text-white tracking-wider leading-15 text-3xl pb-1 font-bold lg:text-5xl">
              Get your Cricket id <br />
            </h1>
          </div>
          <div className="flex flex-col  lg:gap-1">
            <p className="text-brandClr text-3xl font-extrabold font-sans   lg:text-6xl">
              FAST WITHDRAWAL
            </p>
            <p className="text-brandClr text-3xl font-extrabold font-sans pb-2 lg:text-7xl">
              24 X 7 Support
            </p>
          </div>
        </div>

        <div className="customerMarnginMob lg:mt-0 ">
          <div className="flex  items-center justify-center lg:justify-start lg:mx-10 lg:px-8 lg:pt-2  lg:w-w/8">
            <div className="winNow flex flex-col justify-center items-center  mt-0 lg:flex-row lg:gap-6 ">
              <button className="bg-green text-white font-extrabold rounded-2xl px-10 py-5 ">
                <a href="https://wa.link/baazigar?text=I%20want%20new%20id">
                  WhatsApp Now
                </a>
              </button>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="customerMobMargin  lg:bottom-0 lg:right-12   customerMargintop opacity-1 player p-7 bg-bgColor flex flex-col  justify-evenly rounded-lg lg:flex-row lg:mx-8 lg:px-15 lg:h-40 lg:mt-14 lg:pt-10 lg:order  lg:gap-14 ">
              <div className=" pb-4 lg:order-2">
                <div className="flex lg:gap-8">
                  <p className="text-white text-xl font-extrabold pb-3 lg:tracking-tighter">
                    99898+
                  </p>
                  <p className="text-white text-xl font-extrabold lg:tracking-tighter">
                    Gamming
                  </p>
                </div>
                <div className="flex justify-center items-center">
                  <img className="lg:w-22 lg:h-20" src={playerIcon} alt="" />
                </div>
              </div>
              <div className=" lg:flex lg:order-1 lg:gap-16 lg:justify-evenly ">
                <div className="flex justify-between pb-4 lg:gap-12 gap-6">
                  <img className="mobImg   deskImg" src={icon1} alt="" />
                  <img
                    style={{ width: "106px" }}
                    className="mobImg deskImg"
                    src={icon2}
                    alt=""
                  />
                </div>
                <div className="flex justify-evenly pb-4 lg:gap-12  gap-6">
                  <img className="mobImg  deskImg" src={icon3} alt="" />
                  <img className="mobImg deskImg" src={icon4} alt="" />
                </div>
                <div className="flex justify-evenly lg:gap-12 gap-6">
                  <img className="mobImg  deskImg" src={icon5} alt="" />
                  <img className="mobImg   deskImg" src={icon6} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          // style={{ marginTop: "150px" }}
          className="makeDeskRes lg:relative lg:my-2 lg:py-2"
        ></div>

        <div
          id="favSports"
          className="lg:flex relative  items-center   justify-center lg:absolute lg:mx-10 lg:px-8"
        >
          <div className="flex flex-wrap   justify-center items-center  lg:gap-8 gap-8 lg:flex-1">
            <div className="flex gap-8 lg:justify-start justify-center items-center">
              <img
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, rgba(0,0,0,0), rgba(0,0,0,0.5))",
                }}
                className="w-1/3 lg:w-1/5 h-auto rounded-2xl"
                src={basketBall}
                alt=""
              />
              <img
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, rgba(0,0,0,0), rgba(0,0,0,0.5))",
                }}
                className="w-1/3 lg:w-1/5 h-auto   rounded-2xl"
                src={kabbadi}
                alt=""
              />
            </div>
            <div className="flex gap-8 lg:justify-start  justify-center items-center">
              <img
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, rgba(0,0,0,0), rgba(0,0,0,0.5))",
                }}
                className="w-1/3 lg:w-1/5 h-auto rounded-2xl"
                src={football}
                alt=""
              />
              <img
                style={{
                  maxWidth: "140px",
                  maxHeight: "150px",
                  backgroundImage:
                    "linear-gradient(to bottom right, rgba(0,0,0,0), rgba(0,0,0,0.5))",
                }}
                className="w-1/3  lg:w-1/5 h-auto   rounded-2xl py-9 px-7"
                src={tesniss}
                alt=""
              />
            </div>
          </div>
          <div className="rightFavSec lg:flex-1  flex flex-col justify-center lg:justify-start ">
            <div className="desc py-4 flex flex-col justify-center lg:justify-start lg:gap-10">
              <h3 className="text-brandClr font-brand-font font-black text-2xl   pb-4 lg:py-0 lg:pb-0">
                Your Favourite Sports
              </h3>
              <span className="text-white text-3xl lg:text-5xl  font-bold font-brand-font tracking-normal pb-5 ">
                Turn your Hobby into Money Making with India's #1 Cricket id
                with Baazigar
              </span>
            </div>
            <div className="flex justify-center lg:justify-start items-center">
              <button className="bg-green text-white font-extrabold rounded-2xl px-10 py-5 ">
                <a href="https://wa.link/baazigar?text=I%20want%20new%20id">
                  WhatsApp Now
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div id="mobmargin" className="deskmargin  text-white my-2">
        <Footer />
      </div>
    </section>
  );
};

export default SparrowGame;
